import { AppRegistry } from "react-native";

import { ApolloProvider } from "@apollo/client";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { persistedStore, store } from "@redux";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { getApolloClient as Client } from "components/src/apollo";
import { navigationRef } from "components/src/navigation";
import {
  Auth0CallBack,
  DeeplinkConfigs,
  HomepageAdmin,
  HomepagePointage,
} from "./navigation";
import "components/src/utils/icons";
import { Config } from "../../../config";
import * as Sentry from "@sentry/react";

const Stack = createStackNavigator();

declare global {
  interface Window {
    config: Config;
  }
}

Sentry.init({
  dsn: window.config.SENTRY_URL_DSN,
  enabled: window.config.ENV !== 'development' // to prevent reporting errors in dev mode unintentionally
});

export function App() {
  if (!Client()) return null;
  return (
    <ApolloProvider client={Client()}>
      <Provider store={store}>
        <PersistGate persistor={persistedStore}>
          <NavigationContainer
            ref={navigationRef}
            linking={DeeplinkConfigs()}
            documentTitle={{
              formatter: (options, route) => "E-Pointage",
            }}
          >
            <Stack.Navigator initialRouteName={"Root"}>
              <Stack.Screen
                component={HomepagePointage}
                name={"Root"}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                component={HomepageAdmin}
                name={"RootAdmin"}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                component={Auth0CallBack}
                name={"Callback"}
                options={{ headerShown: false }}
              />
            </Stack.Navigator>
          </NavigationContainer>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}

declare let global: {
  HermesInternal?: boolean;
};

AppRegistry.registerComponent("myprojectname", () => App);
AppRegistry.runApplication("myprojectname", {
  rootTag: document.getElementById("root"),
});
